.mental-health-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: "Arial", sans-serif;
    color: #333;
  }
  
  .header {
    margin-top: 50px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #003366;
  }
  
  .description {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .options {
    list-style-type: none;
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .options li {
    display: inline;
    margin: 0 10px;
    font-weight: bold;
  }
  
  .questionnaire {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .question-card {
    background: #f9f9f9;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .question {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .answers {
    display: flex;
    gap: 10px;
  }
  
  .answer-button {
    padding: 8px 16px;
    font-size: 1rem;
    border: 2px solid #003366;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .answer-button:hover {
    background: #003366;
    color: #fff;
  }
  
  .answer-button.selected {
    background: #003366;
    color: #fff;
    border-color: #003366;
  }
  
  .submit-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background: #003366;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover {
    background: #001e3d;
  }
  
  .submit-button:disabled {
    background: #ddd;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .result-card {
    margin-top: 30px;
    padding: 20px;
    background: #e8f3f5;
    border: 2px solid #003366;
    border-radius: 8px;
    text-align: center;
  }
  
  .result-card h2 {
    margin-bottom: 10px;
  }
  
  .score {
    font-size: 1.5rem;
    font-weight: bold;
    color: #003366;
  }
  
  .stress-level {
    font-size: 1.5rem;
    font-weight: bold;
    color: #d32f2f;
  }
