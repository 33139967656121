.container {
  display: flex;
  flex-direction: row; /* Default layout for larger screens */
  justify-content: space-between;
  gap: 10px;
  padding: 2rem;
  margin: 0 100px;
  margin-top: 150px;
  margin-bottom: 150px;
  margin-left: 100px;
  margin-right: 100px;
}

.contact-us-section,
.timeline-section {
  flex: 1;
  max-width: 500px;
  background-color: #eef6fc;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #00000034;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center align for better responsiveness */
}

.contact-us-section {
  background-color: #eef6fc;
}

.timeline-section {
  background-color: #ffffff;
}

/* Reduce spacing for mobile screens */
.contact-heading,
.timeline-heading {
  text-align: center;
  font-size: 28px; /* Smaller size for smaller screens */
  font-weight: 800;
  color: #002855;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-input,
.contact-textarea {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 90%; /* Adjust to fill available space */
  padding: 0.75rem;
  border: 1px solid #cce5ff;
  border-radius: 8px;
  font-size: 16px;
}

.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 30px; /* Default for larger screens */
  top: 0;
  bottom: 0;
  width: 4px;
  background: #cce5ff;
}

.timeline-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.timeline-item .circle {
  position: absolute;
  left: 20px; /* Default for larger screens */
  top: 0;
  width: 20px;
  height: 20px;
  background: #012a63;
  border-radius: 50%;
  border: 4px solid white;
  z-index: 1;
}

.timeline-item .content {
  margin-left: 60px; /* Default for larger screens */
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline-item .content h3 {
  text-align: left;
  font-weight: 400;
  font-size: 16px; /* Adjusted for smaller screens */
  margin: 0;
  color: #012a63;
}

.contactus-description,
.timeline-description {
  text-align: center;
  font-size: 14px;
  color: #0000009f; /* Adjust color to match the scheme */
  margin-bottom: 1rem;
  font-style: italic; /* Optional for emphasis */
  margin-bottom: 50px;
}

.loading-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* --- Responsive Styles --- */
@media (max-width: 768px) {
  .container {
      flex-direction: column; /* Stack sections vertically */
      gap: 2rem;
      padding: 1rem; /* Reduce padding for smaller screens */
      margin: 0 20px; /* Narrow margins */
  }

  .contact-us-section {
    margin: 0 auto; /* Center align both sections */
    max-width: 100%; /* Take full width */
    padding: 1rem;
  }
  
  .timeline-section {
      margin: 0 auto; /* Center align both sections */
      max-width: 100%; /* Take full width */
      padding: 1rem; /* Reduce padding for smaller screens */
  }

  .timeline:before {
      left: 15px; /* Adjust line position */
  }

  .timeline-item .circle {
      left: 5px; /* Adjust circle position */
  }

  .timeline-item .content {
      margin-left: 30px; /* Reduce margin for smaller screens */
  }

  .contact-heading,
  .timeline-heading {
      font-size: 24px; /* Smaller headings for mobile */
  }

  .contactus-description,
  .timeline-description {
      font-size: 16px; /* Smaller font for descriptions */
  }
}
