/* Global Styling */
body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f9f9f9;
}

/* Container for the whole page */
.Women-container {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

/* Back Button */
.Women-back-arrow {
  margin-top: 50px;
  position: absolute;
  top: 30px; 
  left: 20px; 
  font-size: 24px; 
  margin-bottom: 50px;
  font-weight: bold;
  color: #0056b3;
  cursor: pointer;
  transition: color 0.3s ease;
}

.Women-back-arrow:hover {
  color: #007bff;
}

/* Sidebar Styling */
.Women-sidebar {
  margin-top: 100px;
  margin-left: 200px;
  width: 400px;
  background-color: #fdfdff;
  border-right: 2px solid #ddd;
  padding: 20px 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
}

.Women-sidebar h3 {
  font-size: 25px;
  margin-bottom: 30px;
  color: #444;
  font-weight: bold;
}

.Women-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Women-sidebar ul li {
  margin: 30px 20px;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
  transition: color 0.3s ease;
}

.Women-sidebar ul li:hover {
  color: #0056b3;
}

/* Main Content Styling */
.Women-content {
  margin-right: 200px;
  margin-top: 80px;
  flex: 1;
  padding: 20px 30px;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

/* Card Styles */
.Women-menstrual-card {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 25px 20px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border-left: 4px solid #007bff;
}

.Women-menstrual-card:hover {
  transform: translateY(-5px);
}

.Women-menstrual-card h3,
.Women-menstrual-card h2 {
  margin-top: 0;
  color: #444;
  font-weight: bold;
}

.Women-menstrual-card h3 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
}

.Women-menstrual-card ul li {
  font-size: 1.1rem; /* Adjust the size as needed */
  line-height: 1.6; /* Optional: improves readability */
}

.Women-menstrual-card p {
  font-size: 1.1rem; /* Adjust the size as needed */
  line-height: 1.6; /* Optional: improves readability */
}

.Women-cycle-details ul,
.Women-irregular-details ul {
  padding-left: 25px;
  list-style: disc;
}

.Women-cycle-details ul li,
.Women-irregular-details ul li {
  margin-bottom: 8px;
  line-height: 1.6;
}

.Women-tips-header {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: #007bff;
  text-decoration: underline;
}

.Women-tips-header:hover {
  color: #0056b3;
}

.Women-tips-details ul {
  padding-left: 25px;
  margin-top: 10px;
}

.Women-tips-details ul li {
  line-height: 1.6;
  margin-bottom: 8px;
}

.Women-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.Women-table th,
.Women-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.Women-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}


/* Responsive Design */
@media screen and (max-width: 768px) {
  .Women-sidebar {
    display: none;
  }

  .Women-content {
    margin-right: 0px;
    padding: 20px 15px;
  }

  .Women-container {
    flex-direction: column;
  }
}
