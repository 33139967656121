.footer {
  background-color: #012a63;
  color: white;
  padding: 2rem;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center; /* Center-align text on smaller screens */
}

.footer-logo {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 1.5rem;
  flex: 1 1 100%; /* Take full width on smaller screens */
}

.hsuite-logo {
  width: 150px;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
}

.social-icons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.social-icons a {
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

.social-icons a:hover {
  transform: scale(1.1);
}

.copyright {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #ffffff;
}

.footer-links {
  display: flex;
  justify-content: space-between; /* Align sections in a row on larger screens */
  gap: 2rem;
  flex: 1 1 100%; /* Ensure full width for responsive design */
  margin-top: 2rem;
}

.footer-links > div {
  flex: 1; /* Make each section take equal space in a row */
  min-width: 200px; /* Prevent sections from shrinking too small */
}

.footer-links h3 {
  font-size: 20px;
  margin-bottom: 1rem;
  font-weight: bold;
}

.footer-head {
  color: #ffffff;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto; /* Center the list */
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  font-size: 17px;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.footer-links a:hover {
  color: #00bfff;
}

/* --- Responsive Styles --- */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column; /* Stack links in a column */
    align-items: center; /* Center-align sections */
    gap: 1.5rem; /* Add spacing between sections */
  }

  .footer-links > div {
    flex: unset; /* Remove flex grow to center each section */
    width: 100%; /* Take full width on smaller screens */
    text-align: center;
  }
}

@media (max-width: 480px) {
  .hsuite-logo {
    width: 100px; /* Reduce logo size for very small screens */
  }

  .social-icons a {
    font-size: 20px; /* Adjust icon size */
  }

  .footer-links h3 {
    font-size: 18px; /* Adjust heading size for smaller screens */
  }

  .footer-links a {
    font-size: 14px; /* Reduce font size of links */
  }
}
