/* Background and container styling */
.built-for-hospital {
    background: linear-gradient(180deg, #e3f2fd, #ffffff);
    padding: 50px 20px;
    text-align: center;
    font-family: "Inter", sans-serif;
  }
  
  /* Heading styling */
  .heading {
    color: #002855;
    font-size: 36px;
    font-weight: 800; /* Extra Bold */
    margin-bottom: 40px;
  }
  
  /* Images container */
  .images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px; /* Space between images */
    margin-bottom: 40px;
  }
  
  .image-wrapper {
    text-align: center;
  }
  
  .hospital-image {
    width: 500px; /* Adjust according to image dimensions */
    height: auto;
    border: 1px solid #ccc; /* Optional border for visual consistency */
    border-radius: 8px; /* Optional rounded corners */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Hover effect for images */
  .hospital-image:hover {
    transform: scale(1.05); /* Slight zoom-in effect */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
  
  .image-caption {
    margin-top: 12px;
    color: #002855;
    font-size: 18px;
    font-weight: 600;
  }
  
  /* Button styling */
  .view-more-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #4fc3f7;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    border: none;
    border-radius: 30px;
    padding: 12px 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-more-button .arrow-icon {
    margin-left: 8px;
  }
  
  .view-more-button:hover {
    background-color: #42a5f5; /* Slightly darker blue on hover */
  }
  
  /* Subtext styling */
  .sub-text {
    color: #64b5f6;
    font-size: 18px;
    font-weight: 500; /* Semi Bold */
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  /* Responsive styles */
@media (max-width: 768px) {
  .heading {
    font-size: 28px; /* Slightly smaller heading */
    margin-bottom: 30px;
  }

  .images-container {
    flex-direction: column; /* Stack images vertically */
    gap: 20px; /* Reduce gap between images */
  }

  .hospital-image {
    width: 90%; /* Make images responsive to screen size */
    margin: 0 auto; /* Center images */
  }

  .image-caption {
    font-size: 16px; /* Slightly smaller caption */
  }

  .view-more-button {
    font-size: 16px; /* Adjust button font size */
    padding: 10px 20px; /* Adjust padding */
  }

  .sub-text {
    font-size: 18px; /* Adjust subtext size */
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 24px; /* Smaller heading for very small screens */
  }

  .hospital-image {
    width: 100%; /* Full-width images for very small screens */
  }

  .image-caption {
    font-size: 14px; /* Smaller caption for very small screens */
  }

  .view-more-button {
    font-size: 14px; /* Smaller button font size */
    padding: 8px 16px; /* Adjust padding */
  }

  .sub-text {
    font-size: 16px; /* Adjust subtext size */
  }
}
