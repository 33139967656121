.faq-section {
    background-color: #f5faff;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.faq-heading {
    text-align: center;
    color: #003366;
    font-size: 36px;
    font-weight: 800; /* Extra Bold */
    margin-bottom: 100px;
}

.faq-list {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Display in 2 columns */
    gap: 1.4rem; /* Spacing between items */
    column-gap: 4rem;
    max-width: 1400px;
    margin: 0 auto;
}

/* Mobile responsive for FAQ grid */
@media (max-width: 768px) {
    .faq-list {
      grid-template-columns: 1fr; /* Switch to 1 column on smaller screens */
      gap: 1rem;
    }
  
    .faq-heading {
      font-size: 28px;
    }

    .faq-section {
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 20px;
      padding-right: 20px;
  }
  }
  
  @media (max-width: 480px) {
    .faq-heading {
      font-size: 24px;
    }

      .faq-section {
      margin-left: 10px;
      margin-right: 10px;
  }
  }
  
  .faq-item {
    border: 1px solid #cce5ff;
    border-radius: 8px;
    background-color: #e9f7fc;
    padding: 1.3rem;
    transition: box-shadow 0.3s;
  }
  
  .faq-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    color: #002855; /* Updated color */
  }
  
  .faq-question-icon {
    font-size: 18px;
    transition: transform 0.3s;
  }
  
  .faq-answer {
    margin-top: 0.5rem;
    color: #000; /* Black color for the answer */
    font-size: 16px;
    line-height: 1.5;
    display: none; /* Hide by default */
  }
  
  .faq-item.active .faq-answer {
    display: block; /* Show answer when active */
  }
  
  .faq-item.active .faq-question-icon {
    transform: rotate(180deg); /* Rotate "+" to look like "−" */
  }
