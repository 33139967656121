.product-section {
   text-align: "center";
   margin-top: "100px" ;
   margin-bottom: "100px" ; 
}

.product-section h1 {
    text-align: "center";
    margin-top: "100px" ;
    margin-bottom: "100px" ; 
 }

