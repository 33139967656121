.back-arrow {
  margin-top: 50px;
  margin-bottom: 50px;
  position: absolute;
  top: 30px; 
  left: 20px; 
  font-size: 24px; 
  font-weight: bold;
  color: #0056b3;
  cursor: pointer;
  transition: color 0.3s ease;
}

.back-arrow:hover {
  color: #0077f6;
}

.kyh-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container horizontally */
  gap: 20px; /* Space between the rows */
  padding-bottom: 20px;
  /* background-color: #f0f8ff; */
  font-family: 'Inter', sans-serif;
}

.kyh-heading {
  padding-top: 15px;
  margin-top: 50px;
  text-align: center;
    color: #003366;
    font-size: 36px;
    font-weight: 800; /* Extra Bold */
    margin-bottom: 100px;
    text-decoration: none;
}

.kyh-row1, .kyh-row2, .kyh-row3, .kyh-row4, .kyh-row5 {
  display: flex;
  gap: 30px; /* Space between the cards in each row */
  justify-content: center; /* Center align the cards in each row */
}

.kyh-row1, .kyh-row2, .kyh-row3, .kyh-row4, .kyh-row5 {
  flex-wrap: wrap; /* Allow the cards to wrap if necessary */
}

.kyh-card {
  width: 300px; /* Fixed width for the card */
  height: 300px; /* Fixed height for the card */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 4px 4px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center the image and title horizontally */
  text-align: center;
  padding: 30px;
  background-color: #fff; /* Background color of the card */
  text-decoration: none;
  color: inherit;
}

.kyh-card:hover {
  transform: translateY(-5px);
}

.kyh-image {
  width: 150px; /* Adjust the width of the image */
  height: 150px; /* Adjust the height of the image */
  object-fit: contain; /* Ensure the image fits nicely inside the container */
  margin-bottom: 10px; /* Space between the image and the title */
  padding-bottom: 20px;
}

.kyh-image-png {
  width: 100px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1rem;
}


.kyh-card h3 {
  margin: 0;
  font-size: 20px; /* Adjusted font size for the card titles */
}

.kyh-card a {
  text-decoration: none;
  color: inherit;
}

.kyh-card a:hover {
  color: #000; /* Optional: Change color on hover */
}


/* BMI Calculator */
.bmi-chart-calculator {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.chart-heading {
    color: #0056b3;
    padding-top: 20px;
    padding-bottom: 20px;
}

.calculator-heading {
  padding-top: 60px;
  color: #0056b3;
}

table.bmi-chart-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table.bmi-chart-table th, table.bmi-chart-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.bmi-chart-table th {
  background-color: #f2f2f2;
}

.bmi-chart-table tr.underweight td {
  background-color: #b3cde0; /* Light blue for Underweight */
}

.bmi-chart-table tr.normal-weight td {
  background-color: #ccebc5; /* Light green for Normal weight */
}

.bmi-chart-table tr.overweight td {
  background-color: #fddaec; /* Light pink for Overweight */
}

.bmi-chart-table tr.obesity td {
  background-color: #fbb4b9; /* Light red for Obesity */
}

.bmi-calculator {
  margin-top: 20px;
}

.input-group {
  margin: 10px 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calculate-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.calculate-btn:hover {
  background-color: #0056b3;
}

/* Result container to handle background colors */
.bmi-result-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.bmi-result-container div {
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  flex: 1;
  margin: 5px;
}

/* Individual result sections */
.bmi-result h4, .bmi-status h4 {
  color: #333; /* Headings stay black */
}

.bmi-result p, .bmi-status p {
  font-size: 1.5rem;
  color: #333; /* Text remains black */
}

.bmi-result-container.underweight div {
  background-color: #b3cde0; 
}

.bmi-result-container.normal-weight div {
  background-color: #ccebc5; 
}

.bmi-result-container.overweight div {
  background-color: #fddaec; 
}

.bmi-result-container.obesity div {
  background-color: #fbb4b9;
}

/* Color-coded BMI scale */
.bmi-scale {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.scale-segment {
  flex: 1;
  padding: 10px;
  color: white;
  text-align: center;
}

.scale-segment.underweight {
  background-color: #b3cde0; /* Light blue */
}

.scale-segment.normal-weight {
  background-color: #ccebc5; /* Light green */
}

.scale-segment.overweight {
  background-color: #fddaec; /* Light pink */
}

.scale-segment.obesity {
  background-color: #fbb4b9; /* Light red */
}

/* Health Indicators */

/* Styles for health indicators */
.kyh-health-indicators {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hi-heading {
  color: #0056b3;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
}

.kyh-health-indicators h2 {
  text-align: center;
  margin-bottom: 20px;
}

table.kyh-health-indicators-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table.kyh-health-indicators-table th,
table.kyh-health-indicators-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.kyh-health-indicators-table th {
  font-weight: bold;
}

.kyh-health-indicators-table td {
  color: #333;
}

/* Color coding for normal, precondition, and abnormal ranges */
.kyh-normal-range {
  background-color: #ccebc5; /* Light green for Normal */
  color: #333;
}

.kyh-precondition-range {
  background-color: #fcfcbc; /* Light yellow for Pre-condition */
  color: #333;
}

.kyh-abnormal-range {
  background-color: #fbb4b9; /* Light red for Abnormal */
  color: #333;
}

/* Hover effect */
table.kyh-health-indicators-table tr:hover {
  background-color: #f1f1f1;
}


/* Women Health */

.women-image {
  width: 150px; /* Adjust the width of the image */
  height: 150px; /* Adjust the height of the image */
  object-fit: contain; /* Ensure the image fits nicely inside the container */
  margin-bottom: 10px; /* Space between the image and the title */
  padding-bottom: 20px;
}

/* Menstrual Health */

/* Styles for Menstrual Health section */
.kyh-menstrual-health-container {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2, h3 {
  text-align: center;
  color: #333;
}

.kyh-menstrual-card {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.kyh-menstrual-card h3 {
  color: #007bff;
  cursor: pointer;
  margin-bottom: 15px;
  text-align: center;
  transition: color 0.3s ease;
}

.kyh-menstrual-card h3:hover {
  color: #0056b3;
}

.kyh-cycle-details, .kyh-irregular-details, .kyh-tips-details {
  margin-top: 10px;
}

.kyh-cycle-details ul, .kyh-irregular-details ul, .kyh-tips-details ul {
  list-style: disc;
  padding-left: 20px;
}

.kyh-cycle-details li, .kyh-irregular-details li, .kyh-tips-details li {
  margin-bottom: 10px;
}

/* Animation for tips dropdown */
.kyh-tips-details {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Add media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  .kyh-menstrual-health-container {
    padding: 10px;
  }

  .kyh-menstrual-card {
    padding: 15px;
  }

  h2, h3 {
    font-size: 1.4rem;
  }
}

.kyh-appointment-button {
  text-align: center;
  margin: 20px;
}

.appointment-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
