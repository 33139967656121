/* Main container styles */
.why-hsuite {
    background-color: #ffffff;
    padding: 50px 20px;
    text-align: left;
    font-family: "Inter", sans-serif;
  }
  
  .why-hsuite-heading {
    font-size: 36px;
    font-weight: 800;
    color: #002855;
    margin-bottom: 40px;
  }
  
  /* Comparison container */
  .comparison-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
  }
  
  .column {
    flex: 1;
    max-width: 500px;
    border-radius: 10px;
    padding: 20px;
  }
  
  .current-problems {
    background-color: #f7f8fa;
    padding-bottom: 50px;
  }
  
  .hsuite-solutions {
    background-color: #ebf7ff;
    padding-bottom: 50px;
  }
  
  /* Column headings */
  .why-hsuite-headings {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
  }
  
  /* List styles */
  .column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .column li {
    padding-top: 25px;
    padding-left: 70px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 15px;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .icon.cross {
    color: #ff0000;
  }
  
  .icon.check {
    color: #00b300;
  }
  
  /* Footer text */
  .footer-text {
    text-align: center;
    margin-top: 60px;
    font-size: 24px;
    font-weight: 400;
    color: #4b647d;
    margin-bottom: 100px;
  }


/* Responsive styles */
@media (max-width: 768px) {
  .comparison-container {
    align-items: center;
    flex-direction: column; /* Stack columns vertically */
    gap: 20px; /* Reduce gap between columns */
  }

  .column {
    max-width: 100%; /* Make columns take full width */
    padding: 20px 15px;
  }

  .why-hsuite-heading {
    font-size: 28px; /* Slightly smaller heading */
    margin-bottom: 30px;
  }

  .why-hsuite-headings {
    font-size: 24px; /* Adjust headings for smaller screens */
  }

  .column li {
    font-size: 18px; /* Smaller font size for list items */
    padding-top: 20px;
    padding-left: 40px; /* Reduce left padding */
  }

  .icon {
    font-size: 20px; /* Smaller icons */
  }

  .footer-text {
    font-size: 20px; /* Adjust footer text size */
    margin-top: 30px;
  }
}
