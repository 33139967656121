.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  border-bottom: 1px solid #33333349; /* Add a solid border */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: "Inter", sans-serif;
}

  
  .logo img {
    height: 40px;
    margin-left: 200px;
  }
  
  .menu {
    margin-right: 200px;
    display: flex;
    gap: 3rem;
  }
  
  .menu li {
    list-style: none;
  }
  
  .menu a {
    text-decoration: none;
    color: #012a63;
    font-size: 18px;
    font-weight: 500; /* Bold */
    transition: color 0.3s;
  }
  
  .menu a:hover {
    text-decoration: underline;
    text-decoration-color: #012a63;
  }
  
  .menu .active {
    color: #00a8ff;
    font-weight: 500;
  }

  .menu-item {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer; /* Makes "Contact Us" look clickable */
    border: none;
    background: none;
    outline: none;
  }
  
  .menu-item:hover {
    color: #007bff; /* Change color on hover */
  }
  
  .menu-item.active {
    font-weight: bold;
    color: #007bff;
  }
  
  /* Hamburger Menu Styles for Mobile */
.hamburger {
  display: none;
  font-size: 24px;
  color: 012a63;
  cursor: pointer;
}


.logo a {
  display: inline-block;
}

.logo img {
  max-width: 100%; /* Ensure the logo scales properly */
}


/* Mobile Menu Styles */
@media (max-width: 768px) {
  .menu {
    display: none; /* Hide menu by default */
    position: absolute;
    top: 50%;
    right: 0;
    background-color: white;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    text-align: center;
  }

  .menu.open {
    display: flex; /* Show menu when open */
  }

  .menu li {
    margin: 5px 0;
    margin-left: 200px;
  }

  .menu a {
    font-size: 18px;
    font-weight: 600;
  }

  .hamburger {
    display: block; /* Show hamburger icon on mobile */
  }

  .logo img {
    margin-left: 0;
  }
}
