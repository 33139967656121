/* Section container */
.healthcare-features {
  background-color: #ffffff;
  padding: 50px 20px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

/* Heading */
.heading {
  color: #002855;
  font-size: 36px;
  font-weight: 800; /* Extra Bold */
  margin-bottom: 40px;
}

/* Features container */
.features-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 40px;
}

/* Row */
.row {
  display: flex;
  justify-content: center;
  gap: 60px; /* Space between items */
  flex-wrap: wrap; /* Allows wrapping if the screen width is small */
}

/* Individual feature item */
.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
}

.feature-icon {
  width: 50px; /* Adjust size as needed for downloaded icons */
  height: 50px;
  margin-bottom: 12px;
}

.react-icon {
  width: 50px;
  height: 50px;
  color: black; /* Black icons */
  font-weight: 400;
  margin-bottom: 12px;
}

.feature-caption {
  font-size: 14px;
  font-weight: 400; /* Regular */
  color: #000000; /* Black */
  text-align: center;
}

/* Footer text */
.footer-text {
  font-size: 20px;
  font-weight: 500; /* Medium */
  color: #0288d1; /* Blue */
  margin-top: 20px;
}


/* Responsive styles */
@media (max-width: 768px) {
  .features-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  .heading {
    font-size: 28px; /* Slightly smaller heading for tablets */
  }

  .row {
    gap: 80px; /* Reduce spacing between feature items */
  }

  .feature-icon {
    width: 80px; /* Smaller icons */
    height: 80px;
  }

  .react-icon {
    font-size: 50px; /* Reduce icon size */
  }

  .feature-caption {
    font-size: 16px; /* Smaller font size for captions */
  }

  .footer-text {
    font-size: 18px; /* Adjust footer text size */
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 24px; /* Smaller heading for mobile phones */
  }

  .row {
    flex-direction: column; /* Stack features vertically */
    align-items: center; /* Center-align items */
    gap: 40px; /* Further reduce spacing */
  }

  .feature-icon {
    width: 60px; /* Smallest icons for mobile */
    height: 60px;
  }

  .react-icon {
    font-size: 40px; /* Reduce React icon size */
  }

  .feature-caption {
    font-size: 14px; /* Smaller captions for mobile */
  }

  .footer-text {
    font-size: 16px; /* Adjust footer text size */
  }
}
