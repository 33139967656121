.hero-section {
  margin-top: 0px;
  text-align: center;
  padding: 4rem 2rem;
  background-color: white;
  font-family: "Inter", sans-serif;
}

.hero-heading {
  padding-top: 40px;
  font-size: 48px;
  font-weight: 800;
  color: #002855;
  line-height: 1.2;
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-subtext {
  margin-top: 1rem;
  padding-top: 50px;
  font-size: 19px;
  font-weight: 400;
  color: #4b647d;
  max-width: 700px;
  margin: 1rem auto;
  line-height: 1.6;
}

.hero-buttons {
  padding-top: 30px;
  margin-top: 2rem;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.demo-button,
.contact-button {
  padding: 1rem 2.5rem;
  font-size: 20px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
}

/* Demo Button Styles */
.demo-button {
  background-color: #4fc3f7;
  color: white;
}

.demo-button:hover {
  transform: scale(1.05);
  background-color: #0288d1;
}

/* Contact Button Styles */
.contact-button {
  background-color: #f1f9ff;
  color: #0288d1;
  border: 2px solid #0288d1;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #ff0000;
}

.contact-button:hover {
  background-color: #d4f0ff;
  color: #026aa7;
}

.hero-arrow {
  margin-top: 3rem;
  font-size: 2rem;
  color: #002855;
  cursor: pointer;
  transition: color 0.3s, transform 0.2s;
}

.hero-arrow:hover {
  color: #0288d1;
  transform: translateY(5px);
}

/* Demo Form Styling */
.demo-form {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  width: 300px;
  height: auto;/* Updated to position the close button */
}

.demo-form h3 {
  margin-bottom: 1rem;
  color: #002855;
  text-align: center;
  font-size: 20px;
}

.demo-form label {
  text-align: left;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  margin-bottom: 6px;
}

.demo-form input {
  width: 90%;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.demo-form input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.demo-form button {
  width: 100%;
  padding: 0.8rem;
  background-color: #0288d1;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.demo-form button:hover {
  background-color: #005f99;
}

.loading-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .hero-section {
    padding: 2rem 1rem;
  }

  .hero-heading {
    padding-top: 50px;
    font-size: 32px;
    height: auto;
    line-height: 1.3;
  }

  .hero-subtext {
    font-size: 13px;
    line-height: 1.4;
    padding-top: 20px;
    max-width: 100%;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .demo-button,
  .contact-button {
    width: 100%;
    padding: 0.8rem 1.5rem;
    font-size: 18px;
  }

  .demo-form {
    width: 300px;
    bottom: 10px;
    right: 10px;
    width: 90%;
    padding: 1rem;
  }

  .demo-form h3 {
    font-size: 18px;
  }

  .demo-form input {
    font-size: 14px;
    padding: 0.6rem;
  }

  .demo-form button {
    font-size: 14px;
    padding: 0.6rem;
  }

  .hero-arrow {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}









