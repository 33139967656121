.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background-color: #f9f9ff; /* Softer background for better contrast */
  font-family: 'Inter', sans-serif;
}

.blogs-heading {
  text-align: center;
  color: #003366;
    font-size: 36px;
    font-weight: 800; /* Extra Bold */
    margin-bottom: 100px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  max-width: 1400px; /* Slightly wider for better card alignment */
}

.blog-card {
  width: 400px; /* Increased card size for better readability */
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Softer shadow for a modern look */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff; /* White for clean card look */
}

.blog-card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.blog-card img {
  height: 200px;
  width: 400px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.blog-card .card-body {
  text-align: center;
  padding: 20px;
}

.blog-card .card-title {
  font-size: 1.6rem;
  color: #004080; /* A calm blue shade */
  margin-bottom: 10px;
  font-weight: bold;
}

.blog-card .card-text {
  font-size: 1rem;
  color: #555555; /* Subtle text color for readability */
  margin-bottom: 20px;
  line-height: 1.5;
}

.blog-card .btn-primary {
  background-color: #0066cc; /* Updated blue */
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}

.blog-card .btn-primary:hover {
  background-color: #004d99; /* Darker blue on hover */
  transform: translateY(-3px); /* Slight pop-up effect */
}
